import React from 'react';
import { Link } from 'react-router-dom';
import HeaderTop from '../common/HeaderTop'
import { postApiData} from '../../services/Api';
import {validatePhone} from '../../services/validation';

class Join extends React.Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef() ;
        this.state = {
            shown:true,
            first_name:'',
            last_name:'',
            email:'',
            phone:'',
            message:'',

     
        };
      
      }
      componentWillMount() {
       window.scrollTo(0, 0);
       
      }

      handlechange=(e)=>{
        const id = e.target.id;
        const value = e.target.value;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;
        if(id==="first_name" ||id ==="last_name" || id==="message"){
            if(value.trim()==""){
                this.setState({
                [id+'_error']:"Field cannot be empty",
                [id]: value
                })
               }else{
              this.setState({
                [id+'_error']:'',
                [id]:value,
              })
            }
        }
            if(id==="email"){
                if(value.trim()==""){
                    this.setState({
                    [id+'_error']:"Field cannot be empty",
                    [id]: value
                    })
                   }
                   else if(pattern.test(document.getElementById('email').value)===false){
                    this.setState({
                        [id+'_error']:"Enter valid email format",
                        [id]: value
                        })
                   }
                   else{
                  this.setState({
                    [id+'_error']:'',
                    [id]:value,
                  })
                }
            }

         
    }

    handlePhoneChange = event => {
		this.formValidate(event.target.id,event.target.value);
		if(event.target.id==='phone'){
			if(this.isNum(event.target.value)!=true){
			  this.setState({phone:''})
			}
			else{ 
			  this.setState({phone:event.target.value});
			}
		 }
	}

    isNum(num){
		if(/^[0-9-]*$/.test(num))
		return true;
		else 
		return false;
	}

    validation = () => {
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9]{1,99}[\.][a-zA-Z]{2,9}/g;
        return (
          this.state.first_name.trim() !="" &&
          this.state.last_name.trim() != "" &&
          pattern.test(document.getElementById('email').value)===true &&
          this.state.phone.trim() != "" &&
          this.state.message.trim() != "" 
        );
      }

      formValidate(key,value){
        switch(key){
            case 'phone':        
                 this.validatePhone(value);
                break;
            }
    }

      validatePhone(value){
        let res=validatePhone(value);
                   if(res.status==false){
                       this.setState({phone_error: 'Phone number is not valid.'});
                       document.getElementById("phone").value=res.value;
                   }else{
                       this.setState({phone_error: ''});
                       document.getElementById("phone").value=res.value;
                   }
         return res.status;
   }

   

      contactSubmit=(e)=>{
        e.preventDefault();
        let where=[{
          url:'/email/joinUs_form'
        }];
        const formData = new FormData();
        formData.append('first_name',this.state.first_name)
        formData.append('last_name',this.state.last_name)
        formData.append('your_email',this.state.email)
        formData.append('your_msg',this.state.message)
        formData.append('your_phone',this.state.phone)
        postApiData(where,formData).then(res=>{
         if(res){
             this.setState({
                 first_name:'',
                 last_name:'',
                 email:'',
                 phone:'',
                 message:''
             })
          
         }
        }).catch(error=>{
    
        })
      }


    render() {

      
        return (
   
          
            <div className="w-bg FrontEnd Newskyward-design" style={{height:'100vh'}} ref={this.myRef}>
             <HeaderTop/> 
             <div className="conSnapScroll" >
                  
             <div className="showroom-section Join-section" style={{background:'url(/join-bg.png) no-repeat  center center', backgroundSize:'cover'}}>
                    <div className="caption">
                            <h1 className="jointxt">JOIN US AND<br/>
                                TAKE YOUR BUSINESS<br/>
                                SKYWARD</h1>                           
                           
                                                     
                        </div>
                       
                        <div className="goto ">                        
                          <a href="#experience" className="scroll"><img src="/arrow-down.svg"/></a>
                     </div>
                     
                    </div>
                    <div className="panel-2 joinInfo" id="experience">
                    <div className="container workbody" >
                          
                          
                            <div className="experience-section">
                              
                                <div className="row">
                                        <div className="col-md-12">

                                            <div className="exp-content">
                                                <p className="status">1</p>
                                                <h1>JOIN THE MOST ADVANCED AGENT PLATFORM</h1>
                                                <p className="InfoTxt">We are empowering agents with the most innovative technology platform
in the industry, saving you time and effort. Our automated agent tools
and VR platform gives you more time to run your business.</p>
                                            </div>
                                         
                                            </div>

                                            <div className="col-md-12">
                                      
                                        <div className="exp-content">
                                        <p className="status">2</p>
                                                <h1>RUN YOUR AGENT BUSINESS<br/>
                                                HOW YOU RUN IT BEST</h1>
                                                <p className="InfoTxt">Our cloud-based, software-first model allows us to lets us offer the most
progressive, flexible splits in the industry. Have your influence and mentorship be rewarded with our equity and revenue sharing programs.</p>
                                        </div>
                                       
                                    </div> 

                                    <div className="col-md-12">
                                     
                                        <div className="exp-content">
                                        <p className="status">3</p>
                                                <h1>ACCELERATE YOUR SUCCESS<br/>
WITH OUR COMPREHENSIVE TRAINING</h1>
                                                <p className="InfoTxt">Our success starts with your growth. When you join Skyward, you are
receiving a commitment from us that you have access to comprehensive
training from our coaches, training materials, and programs.</p>
                                        </div>
                                       
                                       
                                    </div>
                                
                                </div>
                             
                            </div>
                          
                        </div>
                       
                    
                        <div className="container workbody mt-5" >
                          
                          
                          <div className="experience-section">
                            
                          <div className="exp-content" style={{marginBottom:'0'}}>
                                               
                                                <h1>CONTACT US</h1>
                                              
                            </div>
                            
                              <div className="container contact-form">
                                <div className="row">
                                    <div className="col-md-6">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input
                                          type="text" 
                                          className="form-control"
                                          id="first_name"
                                          value={this.state.first_name}
                                          onChange={this.handlechange}
                                        />  
                                        <br />
                                        <span className="inline-error-class">
                                          {" "}
                                          {this.state.first_name_error !== ""
                                            ? this.state.first_name_error
                                            : ""}
                                        </span>  
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Last Name</label>
                                      <input
                                        type="text" 
                                        className="form-control"
                                        id="last_name"
                                        value={this.state.last_name}
                                        onChange={this.handlechange}
                                      />  
                                      <br />
                                      <span className="inline-error-class">
                                        {" "}
                                        {this.state.last_name_error !== ""
                                          ? this.state.last_name_error
                                          : ""}
                                      </span>  
                                    </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Email</label>
                                          <input
                                            type="text" 
                                            className="form-control"
                                            id="email"
                                            value={this.state.email}
                                            onChange={this.handlechange}
                                          />  
                                          <br />
                                          <span className="inline-error-class">
                                            {" "}
                                            {this.state.email_error !== ""
                                              ? this.state.email_error
                                              : ""}
                                          </span>  
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group">
                                            <label>Phone</label>
                                            <input
                                              type="text" 
                                              className="form-control"
                                              id="phone"
                                              value={this.state.phone}
                                              onChange={this.handlePhoneChange}
                                              minLength='12' 
                                              maxLength='12'
                                            />  
                                            <br />
                                            <span className="inline-error-class">
                                              {" "}
                                              {this.state.phone_error !== ""
                                                ? this.state.phone_error
                                                : ""}
                                            </span>  
                                          </div>
                                      </div>
                                      <div className="col-md-12">
                                      <div className="form-group">
                          <label>Message</label>
                          <input
                            type="text" 
                            className="form-control"
                            id="message"
                            value={this.state.message}
                            onChange={this.handlechange}
                          />  
                           <br />
                          <span className="inline-error-class">
                            {" "}
                            {this.state.message_error !== ""
                              ? this.state.message_error
                              : ""}
                          </span>  
                        </div>
                                      </div>
                                     
                                </div>
                        
                       
                       
                    

                        <button variant="primary" className="btn btn-default mb-5" 
                              disabled={!this.validation()}
                              type="button"
                              onClick={this.contactSubmit}
                              >
                                  
                                Contact Us
                              </button>
                       
                              </div>
                          </div>
                        
                      </div>
                    </div>
               
                    <footer className>
                   <div className="container">
                   <ul className="navbar-nav">
                     
                     <li className="nav-item"><a href="/frontend/landing#experience" > The Experience</a></li>
                     <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                     <li className="nav-item"><Link to="/frontend/join"> Join Us</Link></li>
                     {/* <li className="nav-item"><Link to="/client/listing/browselist" > Browse Listings</Link></li> */}
                     <li className="nav-item"><a href="https://blog.moveskyward.com/" target="_blank" > Blog </a></li>  
                     <li className="nav-item"><Link to="/frontend/privacy-policy"> Privacy Policy</Link></li>
                     <li className="nav-item"><Link to="/frontend/terms-and-condition"> Terms</Link></li>
        
                  
                    
                    </ul>   
                    <div className="clearfix new_logo">
                       <ul className="left-nav"  style={{display:'none'}}>
                           <li><Link to="/frontend/career">CAREERS</Link></li>
                           <li><img src="/home-icon.png"/></li>
                       </ul>
                       {/* <img src="/NYCFC_AP3_Web.jpg"/> */}
                       <div className="logo" style={{marginTop:-36}}></div>
                       <ul className="right-nav" style={{display:'none'}}>
                           <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                       </ul>
                   </div>
                   <p className='text_1' style={{marginTop:48}}>*Skyward Steel, LLC DBA Skyward and Skyward Real Estate is committed to adhering to the guidelines of The New York State Fair Housing Regulations.
<br></br>
*Standardized Operating Procedure for Purchasers of Real Estate Pursuant to Real Property Law 442-H. <a href="/Legal_SOP_NYSAR_032122.pdf" style={{textDecoration:'underline'}} target="_blank">To View Please Click Here.</a>


</p>     </div>
               </footer> 
              


              </div>
              
    </div>

                );
    }
}
export default Join;
