import React from 'react';
import { Link } from 'react-router-dom';
import 'react-input-range/lib/css/index.css';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";
import {checkAdminToken, postApiData, getApiData} from '../../services/Api';
import { compose, withProps, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import Header from '../common/FrontHeader';



    
class Location extends React.Component {
    constructor(props) {
        super(props);     
     this.state={
        addressList:[],
     }
      }
      componentWillMount =()=>{
        this.getAddressList();
    }
    componentDidMount() {
        window.scrollTo(0, 0);
       
      }
       //Get Address list from DB
    getAddressList=()=> {
        let applicationList = [{
            url: "/admin/addressList",
        }];
        getApiData(applicationList,true).then(res => {
            this.setState({'addressList': res.data,'pageLoader':true},()=>{
                    this.setState({'pageLoader':false});
            })
            }).catch(error => {
            if(error.response){
                console.log("error :", error)
                this.setState({myerrormsg: error.response.data.error.message});
            }
        });
    }
    getPosition(position){
        this.setState(position)
      }

  
    render() {
        const MyMapComponent = compose(
            withProps({
                googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC-TZGgCpmB4KOB9fEXziuJE1aAGOPUNdM&libraries=geometry,drawing,places",
                // googleMapURL: "http://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCQ01ecqDWTYQnMlfK-hMJQ6_twhg8a3dg",
                loadingElement: <div style={{ height: `500%` }} />,
                containerElement: <div className="mapdiv" id="mapdiv" style={{ height: `600px`, width : `400px`}}/>,
                mapElement: <div style={{ height: `100%` }} />,
            }),
            lifecycle({
                componentWillMount() {
                    const refs = {}
        
                    this.setState({
                        position: null,
                        onMarkerMounted: ref => {
                            refs.marker = ref;
                        },
        
                        // onPositionChanged: () => {
                        //     var position = refs.marker.getPosition()
                        //     var lat = position.lat()
                        //     var lng = position.lng()
                        //     document.getElementById("newlat").value=lat.toString();
                        //    document.getElementById("newlng").value=lng.toString();
                            
                        // }
                    })
                },
            }),
            withScriptjs,
            withGoogleMap
        )((props) =>
        
             <GoogleMap defaultZoom={13} defaultCenter={{ lat:-1.2814065578196963, lng:36.82600366668703}} >
                {props.isMarkerShown && 
              this.state.addressList.map((mark,index)=>{
                return   <Marker position={{ lat: parseFloat( mark.latitude),  lng: parseFloat(mark.longitude)}}   
                  draggable={false} ref={props.onMarkerMounted} onPositionChanged={props.onPositionChanged} key={index} />
                })
                }
         
            </GoogleMap>
 
            )
        
        return (
   

            <div className="w-bg FrontEnd" style={{height:'100vh'}}>  
              <Header />          
                <div className="container workbody">
                   
                    <div className="experience-section location-section">
                     
                        <div className="row clearfix">

                            <div className="col-sm-6">
                            <h2 className="title">Skyward Location</h2>
                            <div className="location">
                            {this.state.addressList.map((item,index)=>{
                               return <p key={index}>
                                    <strong>{item.name}</strong><br/>
                                        {item.address_line_1} <br/>
                                      {item.address_line_2}<br/>
                                       {item.mob_number}<br/>
                                    {item.timings} <br/> 
                                    </p>
                            })}
                                </div>
                              
                            </div>
                            <div className="col-sm-6">
                            <div className="R_map">
                            {/* <img src="../v-map.png"/> */}
                            <MyMapComponent isMarkerShown={true} getPosition={this.getPosition} />
                        </div>
                            </div>

                           
                                
                        </div>

                        {/*<p className="text-center"><strong><Link to>SEE A COMPLETE LIST OF SHOWROOMS</Link></strong></p>*/}
                        <p className="text-center"><small>Are you an agent? <Link to>Join us!</Link></small></p>
                        <div>
                            <nav className="footer-nav">
                                <ul className="nav">
                                    {/* <li className="nav-item"><Link to className="nav-link">LOG IN</Link></li> */}
                                    <li className="nav-item active"><Link to="/frontend/landing" className="nav-link">THE EXPERIENCE</Link></li>
                                    <li className="nav-item"><Link to ="/frontend/agents" className="nav-link">AGENTS</Link></li>
                                </ul>
                            </nav>

                        </div>

                    </div>
                </div>
                <footer>
                    <div className="container">
                    <div className="clearfix">
                        <ul className="left-nav">
                            <li><Link to="/frontend/career">CAREERS</Link></li>
                            <li><img src="/home-icon.png"/></li>
                        </ul>
                        <div className="logo"></div>
                        <ul className="right-nav">
                        <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
<p className="text-center"><small>280 West 115th Street, 1F |  New York, NY 10026 |  212.222.2843</small></p>
                    </div>
                </footer>
            </div>

                );
    }
}
export default Location;
