import React , { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-input-range/lib/css/index.css';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";
import { Url } from 'url';
import { getApiData,getToken,getUserId,getBaseUrl,postApiData } from '../../services/Api';
import Header from '../common/FrontHeader'
import ListDetails from '../common/listDetails';

import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

let key = encHex.parse("0123456789abcdef0123456789abcdef");
let iv =  encHex.parse("abcdef9876543210abcdef9876543210");  

class AgentDetail extends React.Component {
    constructor(props) {
        super(props);     
     this.state={
        shown: true,
         agentData:[],
         agent_id:this.props.match.params.id,
         agentListingData:[],
         pastdeal_agentListingData:[],

         clickedId:'',
         open: false,
         sendToLogin:false,
         favList:[],

         redirectOnborading:false,
            redirectDashboard: false,
            redirectApplyNow:false
         }

         this.setWrapperRef = this.setWrapperRef.bind(this);
         this.handleClickOutside = this.handleClickOutside.bind(this);
      }

      onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
      this.setState({ open: false });
    };

      componentWillMount(){
        this.getAgentList();
        this.agentListingData();
        this.agent_pastdeal_ListingData();
        let userId;
        if (getToken()) {
            userId=getUserId();
          let filter={
                userId:userId,
            };
            let saveToFav = [{
                url: "/favList",
                where:filter
            }];
            getApiData(saveToFav,true).then(res => {
                   this.setState({favList:res.data});
            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message});
                }
            });
        }
      }

      //Agent Details for front 
	  getAgentList= () => {
        let whereFind = [{
            url: "/front/agent/list/"+this.state.agent_id
        }];
        let agent_id = this.state.agent_id;
        postApiData(whereFind,{'agent_id':agent_id}).then(res => {
            this.setState({'agentData': res.data,'pageLoader':true},()=>{
                this.setState({'pageLoader':false});
            })
            this.state.agentData.map((item,index)=>{
               document.getElementById('description').innerHTML=unescape(item.description).replace(/(?:\r\n|\r|\n)/g, '<br />')
            });
        })
        .catch(error => {
        if(error.response){
            console.log("error :", error)
            this.setState({myerrormsg: error.response.data.error.message});
        }
        });
    }

    agentListingData = ()=>{
        let where=[{
            url:'/front/agent/listingData',
            urlParms:"?id="+this.state.agent_id
        }];
        getApiData(where,false).then(res=>{
          console.log('res',res);
          this.setState({'agentListingData': res.data,'pageLoader':true},()=>{
            this.setState({'pageLoader':false});
        })
        }).catch(error=>{

        })
    }
    saveToFavList=param=>e=>{
        let listindId=param[0];
        let userId;
        if (getToken()) {
            userId=getUserId();
            if(userId!='' && userId!=null){
            let filter={
                userId:userId,
                listId:listindId
            };

            let saveToFav = [{
                url: "/saveToFav",
                where:filter
            }];
            getApiData(saveToFav,true).then(res => {
                this.setState({favList:res.data},()=>{
                    if(res.data.insertId!=0){
                        document.getElementById('fav_'+listindId).classList.add("active");
                        if(document.getElementById('favList'+listindId)!=null){
                            document.getElementById('favList'+listindId).classList.add("active");
                        }
                       
                    }else{
                        document.getElementById('fav_'+listindId).classList.remove("active");
                        if(document.getElementById('favList'+listindId)!=null){
                        document.getElementById('favList'+listindId).classList.remove("active");
                        }
                    }
                });
            }).catch(error => {
                if(error.response){
                    this.setState({myerrormsg: error.response.data.error.message});
                }
            });
        }
        }else{
            this.setState({sendToLogin:true});
        }
    }

    toggle=param=>e=>{
        this.setState({clickedId:''});
        this.setState({
            shown: !this.state.shown
		},()=>{
            if(this.state.shown){
                this.setState({clickedId:''});
            }
           
            if(!this.state.shown && param[0]!=''){
                this.setState({clickedId:param[0]});
              }else{
                  if(param[0]!=''){
                    this.setState({clickedId:param[0],shown:false});
                  }
              }
             
              
        });
        }

        modalClose=param=>e=>{
            this.setState({
            shown: !this.state.shown
		},()=>{
            console.log('this.state.shown',this.state.shown)
            if(this.state.shown){
                this.setState({clickedId:''});
                window.location.reload();
            }
        });
        }
        checkLoginBeforeShowing=param=>e=>{
            if(param[0]=='applyNow'){
                let clientId;
                if (getToken()) 
                {
                    console.log("address", param[1]);
                    console.log("unit", param[2]);
    
                    clientId=getUserId();
                        if(clientId!='' && clientId!=null){
                        let filter={
                            clientId:clientId,
                        };
    
                        let checkForApplyNow = [{
                            url: "/checkForApplyNow",
                            where:filter
                        }];
                        getApiData(checkForApplyNow,true).then(res => {
                            console.log("res applyNow---",res.data);
                            if(res.data.length<=0){
                                this.setState({redirectOnborading: true, redirectApplyNow:false});      //redirect to onboarding
                            }
                            else if(!res.data[0].is_completed)
                            {
                                this.setState({redirectOnborading: true , redirectApplyNow:false});      //redirect to onboarding
                            }
                            else if(!res.data[0].emailVerified){
                                this.setState({redirectDashboard:true , redirectApplyNow:false});   //redirect to dashboard
                            }
                            else{
                                   // this.setState({redirectOnborading: false,redirectDashboard:false, redirectApplyNow:true});
                                    this.setState({redirectOnborading: false,redirectDashboard:false, redirectApplyNow:true},()=>{
                                        // this.props.history.push({
                                        //     pathname:"/client/application",
                                        //     state: { apartment_address: param[1], unit_number: param[2] }
                                            
                                        //    });
                                        localStorage.setItem('apartment_address',JSON.stringify(param[1]));
                                        localStorage.setItem('unit_number',JSON.stringify(param[2]));
                                        localStorage.setItem('building_id',JSON.stringify(param[3]));
    
                                });
                            }
                        }).catch(error => {
                            if(error.response){
                                this.setState({myerrormsg: error.response.data.error.message});
                            }
                        });
                    }
                    //return true;
                }
                else{
                    this.setState({sendToLogin:true});
                }
            }
            else
            {
                if (getToken()) 
                {
                    return true;
                }
                else{
                    this.setState({sendToLogin:true});
                } 
            }
            
    
        }

        componentDidMount() {
            document.addEventListener('mousedown', this.handleClickOutside);
          
                window.scrollTo(0, 0);
           
          }
        
          componentWillUnmount() {
            document.removeEventListener('mousedown', this.handleClickOutside);
          }
        setWrapperRef(node) {
            this.wrapperRef = node;
          }
        
        handleClickOutside(event) {
            console.log("---check--",event.target.id);
            if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            //   alert('You clicked outside of me!');
            }
            if(event.target.id=='ListingModal')
            {
                //window.location.reload()
            }
          }

          //--agent past deals---
          agent_pastdeal_ListingData = ()=>{
            let where=[{
                url:'/front/agent/agentpastdeal_listingData',
                urlParms:"?id="+this.state.agent_id
            }];
            getApiData(where,false).then(res=>{
              console.log('res',res);
              this.setState({'pastdeal_agentListingData': res.data,'pageLoader':true},()=>{
                this.setState({'pageLoader':false});
            })
            }).catch(error=>{
    
            })
        }
    
    render() {
        if(this.state.sendToLogin==true){
            window.location.href='/client/login';
            //return (<Redirect to={'/client/login'}/>);
        }
        else {
            if(this.state.redirectOnborading==true){
                window.location.href='/client/onboarding';
                //return (<Redirect to={'/client/onboarding'}/>);
            }
            else  if(this.state.redirectDashboard==true){
                window.location.href='/client/dashboard';
               // return (<Redirect to={'/client/dashboard'}/>);
            }
            else  if(this.state.redirectApplyNow==true){
                window.location.href='/client/application/';
               // return (<Redirect to={'/client/application/'}/>);
               
            }
        }
         var shown = {
			display: this.state.shown ? "block" : "none"
		};
		
		var hidden = {
			display: this.state.shown ? "none" : "block"
        }
       
  
        let listImage = this.state.agentListingData.map((item, index)=>{
            console.log("item.kuula_vr_link=>",item.id,item.kuula_vr_link)
            let img =(item.large_image!='' && item.large_image!=null)?item.large_image:'/listing-img-detail.png';
            let check=img.indexOf("https://");
            let image=(check >-1 || img=='/listing-img-detail.png')  ? img : getBaseUrl()+"/containers/listing_images/download/" + img;
            // const vr_link=(item.kuula_vr_link!='')?item.kuula_vr_link:'#';
            return <div className="img-wrap" style={{
                backgroundImage:
                'url('+ image  +') '}}>
            </div>
        });

        let dataCheck ;
       
          
        return (
   

            <div className="w-bg FrontEnd " style={{height:'100vh'}}>     
                  <Header />   
                <div className="container workbody curtain ourteam-section">
            {this.state.agentData.map((item,index)=>{
                return  <div className="agent-detail-section clearfix" key={index}> 
                
                {item.image.startsWith("https://")?<div className="team-img thumb-view"  id={item.id} style={{background:'url('+ item.image +') center', backgroundSize:'cover', width:'240px', height:'240px', float:'left'}} ></div>:<div className="team-img"  id={item.id} style={{background:'url('+ getBaseUrl()+"/containers/agent_image/download/"+item.image+') center', backgroundSize:'cover',  width:'240px', height:'240px', float:'left'}}></div>}        
                {/* <div className="team-img thumb-view" style={{background:'url('+ item.image +') center', backgroundSize:'cover' }}></div> */}
                    <div className="agent-info">
                        <div className="row clearfix">
                            <div className="col-sm-12">
                                   <p className="name">{item.name!=="NA"? item.name:''}</p>
                                  <div style={{minHeight:'100px'}}>
                                       <p className="clienttitle">{item.title!=='NA' ? item.title:''}</p>
                                       <p className="phnumber">{item.mob_number!=='NA' ? aes.decrypt(item.mob_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):''}</p>
                                   <p className="email">{item.email!=='NA' ?  aes.decrypt(item.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):''}</p>
                           
                                       <p className="discription" id="description"> </p>

                                       </div>

                            </div>
                            {/*<div className="col-sm-12">
                                   <p className="phnumber">{item.mob_number!=='NA' ? aes.decrypt(item.mob_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):''}</p>
                                   <p className="email">{item.email!=='NA' ?  aes.decrypt(item.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8):''}</p>
            </div>*/}
                        </div>
                     
                    </div>
                    
                </div>
            })}       
                  
                    

                        <div className="listing-result agent-listing agentlistinner" style={{marginTop:'40px'}}>
                        {
                            this.state.agentData.map((val,index)=>{
                                return   <h2 className="listing-title" key={index}>{val.name}'s Listings</h2>
                            })
                        }
                            
                                   <div className="row clearfix member-layout"  >
                                   {this.state.agentListingData.length> 0 ? 
                                   this.state.agentListingData.map(item=>{
                                           // check=item.large_image.indexOf("https://");
                                           let img =(item.large_image!='' && item.large_image!=null)?item.large_image:'/listing-img-detail.png';
                                           let check=img.indexOf("https://");
                                           let image=(check >-1 || img=='/listing-img-detail.png')  ? img : getBaseUrl()+"/containers/listing_images/download/" + img;
                                           return  <div className="col-sm-6 col-md-4" >
                                          
                                            <div className="list-frame"> 
                                            {item.kuula_vr_link?<div className="img-360" style={{right:'25px'}}>
                       <img src="/images/360.png" /> 
                    </div>:''}
                                            {item.noFee?<div className="tag"></div>:''}
                                            <Carousel
  additionalTransfrom={0}
  arrows
  autoPlaySpeed={3000}
  centerMode={false}
  containerClass="container"
  dotListClass=""
  draggable
  focusOnSelect={false}
  infinite
  itemClass=""
  keyBoardControl
  minimumTouchDrag={80}
  renderDotsOutside={false}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
  }}
  showDots
  sliderClass=""
  slidesToSlide={1}
  swipeable
>
        

                {item.image.id!=0 && item.image!='' ? item.image.map(img_data=>{
                   
                     let img =(img_data.thumbnail_image!='' && img_data.thumbnail_image!=null)?img_data.thumbnail_image:'/listing-img.png';
                     let check=img.indexOf("https://");
                     let image=(check >-1 || img=='/listing-img.png')  ? img : getBaseUrl()+"/containers/listing_images/download/" + img;
                 return    <Link to onClick={this.toggle([item.id])} className="view-full"   data-toggle="modal" data-target="#ListingModal"><img src={image} style={{transform: `rotate(${img_data.rotation}deg)`}} /></Link>
                })  :  <Link to onClick={this.toggle([item.id])}   data-toggle="modal" data-target="#ListingModal">  <img src='/listing-img.png' /></Link>
                }

               
            </Carousel>
           
                                          
                                          
                                            <Link to onClick={this.toggle([item.id])}  data-toggle="modal" data-target="#ListingModal">
                                            {/* <div className="img-wrap" style={{background:'url('+ image+')center', backgroundSize:'cover'}}></div> */}
                                                        <div className="listing-info clearfix">
                                                        <h3 className="address">{item.listing_info_address}, {item.unit_number}</h3>
                                                        <div className="cleafix">
                                                        <div className="pull-left">
                                                        <p className="area">{unescape(item.neighborhood)}</p>
                                                        <p className="price">{item.price?'$'+item.price.toLocaleString():'$'+item.price}</p>
                                                        <p className="room-info">{item.bedrooms} br, {item.bathrooms} ba</p>
                                                        </div>
                                                        </div>
                                                        </div>  
                                                        </Link>
                                                        <div className="pull-right"><Link to className={(this.state.favList.length>0 && this.state.favList.find(list => list.listId === item.id))?'star-block active':'star-block'} id={`fav_`+item.id} onClick={this.saveToFavList([item.id])}><svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className="star" d="M33.6667 12.4L21.6834 11.3667L17 0.333374L12.3167 11.3834L0.333374 12.4L9.43337 20.2834L6.70004 32L17 25.7834L27.3 32L24.5834 20.2834L33.6667 12.4ZM17 22.6667L10.7334 26.45L12.4 19.3167L6.86671 14.5167L14.1667 13.8834L17 7.16671L19.85 13.9L27.15 14.5334L21.6167 19.3334L23.2834 26.4667L17 22.6667Z" fill="#BEB7B3"/>
</svg></Link></div>
                                                   
                                               
                                            </div> 
                                         </div>
                                
                                   })
                                :  <div className="noListingFoundDiv"  >No listing found</div>  } 
                                   </div> 
                         </div>
                         {/* --start agent past deals--    */}

                         <div className="listing-result agent-listing agentlistinner" style={{marginTop:'40px'}}>
                        {
                            this.state.agentData.map((val,index)=>{
                                return   <h2 className="listing-title" key={index}>{val.name}'s Past Deals</h2>
                            })
                        }
                            
                                   <div className="row clearfix member-layout"  >
                                   {this.state.pastdeal_agentListingData.length> 0 ? 
                                   this.state.pastdeal_agentListingData.map(item=>{
                                           // check=item.large_image.indexOf("https://");
                                           let img =(item.large_image!='' && item.large_image!=null)?item.large_image:'/listing-img-detail.png';
                                           let check=img.indexOf("https://");
                                           let image=(check >-1 || img=='/listing-img-detail.png')  ? img : getBaseUrl()+"/containers/listing_images/download/" + img;
                                           return  <div className="col-sm-6 col-md-4" >
                                        
                                            <div className="list-frame">
                                            {item.kuula_vr_link?<div className="img-360" style={{right:'25px'}}>
                       <img src="/images/360.png" /> 
                    </div>:''}
                {item.noFee?<div className="tag"></div>:''}                   
                    <Carousel
  additionalTransfrom={0}
  arrows
  autoPlaySpeed={3000}
  centerMode={false}
  containerClass="container"
  dotListClass=""
  draggable
  focusOnSelect={false}
  infinite
  itemClass=""
  keyBoardControl
  minimumTouchDrag={80}
  renderDotsOutside={false}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
  }}
  showDots
  sliderClass=""
  slidesToSlide={1}
  swipeable
>
        

                {item.image.id!=0 && item.image!='' ? item.image.map(img_data=>{
                   
                     let img =(img_data.thumbnail_image!='' && img_data.thumbnail_image!=null)?img_data.thumbnail_image:'/listing-img.png';
                     let check=img.indexOf("https://");
                     let image=(check >-1 || img=='/listing-img.png')  ? img : getBaseUrl()+"/containers/listing_images/download/" + img;
                 return    <Link to onClick={this.toggle([item.id])} className="view-full"  data-toggle="modal" data-target="#ListingModal"><img src={image} style={{transform: `rotate(${img_data.rotation}deg)`}} /></Link>
                })  :  <Link to onClick={this.toggle([item.id])}   data-toggle="modal" data-target="#ListingModal">  <img src='/listing-img.png' /></Link>
                }

               
            </Carousel>
 
                                            <Link to onClick={this.toggle([item.id])}   data-toggle="modal" data-target="#ListingModal">
                                            {/* <div className="img-wrap" style={{background:'url('+ image+')center', backgroundSize:'cover'}}></div> */}
                                                        <div className="listing-info clearfix">
                                                        <h3 className="address">{item.listing_info_address}, {item.unit_number}</h3>
                                                        <div className="cleafix">
                                                        <div className="pull-left">
                                                        <p className="area">{unescape(item.neighborhood)}</p>
                                                        <p className="price">{item.price?'$'+item.price.toLocaleString():'$'+item.price}</p>
                                                        <p className="room-info">{item.bedrooms} br, {item.bathrooms} ba</p>
                                                        </div>
                                                        </div>
                                                        </div>  
                                                        </Link>
                                                        <div className="pull-right"><Link to className={(this.state.favList.length>0 && this.state.favList.find(list => list.listId === item.id))?'star-block active':'star-block'} id={`fav_`+item.id} onClick={this.saveToFavList([item.id])}><svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path className="star" d="M33.6667 12.4L21.6834 11.3667L17 0.333374L12.3167 11.3834L0.333374 12.4L9.43337 20.2834L6.70004 32L17 25.7834L27.3 32L24.5834 20.2834L33.6667 12.4ZM17 22.6667L10.7334 26.45L12.4 19.3167L6.86671 14.5167L14.1667 13.8834L17 7.16671L19.85 13.9L27.15 14.5334L21.6167 19.3334L23.2834 26.4667L17 22.6667Z" fill="#BEB7B3"/>
</svg></Link></div>
                                                   
                                               
                                            </div> 
                                         
                                        </div>
                                   })
                                :  <div className="noListingFoundDiv"  >No listing found</div>  } 
                                   </div> 
                         </div>

                         {/* ---End agent past detals-- */}
                        
                        <div>
                            <nav className="footer-nav">
                                <ul className="nav">
                                    {/* <li className="nav-item"><Link to className="nav-link">LOG IN</Link></li> */}
                                    <li className="nav-item "><Link to ="/frontend/landing" className="nav-link">THE EXPERIENCE</Link></li>
                                    <li className="nav-item active"><Link to ="/frontend/agents" className="nav-link">AGENTS</Link></li>
                                </ul>
                            </nav>

                        </div> 

                    
                </div>
                <div className="Newskyward-design">
                <footer style={{top:'-12px'}}>
                   <div className="container">
                   <ul className="navbar-nav">
                     
                     <li className="nav-item"><a href="/frontend/landing#experience" > The Experience</a></li>
                     <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                     <li className="nav-item"><Link to="/frontend/join"> Join Us</Link></li>
                     {/* <li className="nav-item"><Link to="/client/listing/browselist" > Browse Listings</Link></li> */}
                     <li className="nav-item"><a href="https://blog.moveskyward.com/" target="_blank" > Blog </a></li>  
                     <li className="nav-item"><Link to="/frontend/privacy-policy"> Privacy Policy</Link></li>
                     <li className="nav-item"><Link to="/frontend/terms-and-condition"> Terms</Link></li>
                     
                  
                    
                    </ul>   
                    <div className="clearfix new_logo">
                       <ul className="left-nav"  style={{display:'none'}}>
                           <li><Link to="/frontend/career">CAREERS</Link></li>
                           <li><img src="/home-icon.png"/></li>
                       </ul>
                       <img src="/NYCFC_AP3_Web.jpg"/>
                       <div className="logo"></div>
                       <ul className="right-nav" style={{display:'none'}}>
                           <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                       </ul>
                   </div>
                   <p className='text_1'>  *The Skyward Team and Skyward Steel, LLC is committed to adhering to the guidelines of The New York State Fair Housing Regulations. <a style={{textDecoration:'underline'}} href="https://dos.ny.gov/system/files/documents/2021/08/fairhousingnotice.pdf" target="_blank">To view The Fair Housing Notice-Please click here</a>
<br></br>
*Standardized Operating Procedure for Purchasers of Real Estate Pursuant to Real Property Law 442-H. <a href="https://kwnyc.com/app/uploads/2022/03/Standardized-Operating-Procedure-for-Purchasers-of-Real-Estate.pdf" style={{textDecoration:'underline'}} target="_blank">To View Please Click Here.</a>
<br></br>
*The website to the Team's brokerage (Skyward) is <a href="https://kwnyc.com" style={{textDecoration:'underline'}} target="_blank">www.kwnyc.com.</a>
                </p>   </div>
               </footer> 

                </div>
                <div id="ListingModal" className="modal fade" role="dialog"   >
                 <div className="modal-dialog">
                   {(this.state.clickedId!='')?<ListDetails _listId_={this.state.clickedId} closeModal={this.modalClose.bind(this)} clickToggle={this.toggle.bind(this)} saveToMYFavList={this.saveToFavList.bind(this)} checkLoginFORShowing={this.checkLoginBeforeShowing.bind(this)}/>:''}
                </div>
                </div>
                        {/* <Modal open={this.state.open} onClose={this.onCloseModal} center>
                        {(this.state.clickedId!='')?<ListDetails _listId_={this.state.clickedId}  clickToggle={this.toggle.bind(this)} saveToMYFavList={this.saveToFavList.bind(this)}/>:''}
                        </Modal> */}
            </div>

                );
    }
}
export default AgentDetail;
