import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import 'react-input-range/lib/css/index.css';
import { Button, FormGroup, FormControl,Checkbox, Radio } from "react-bootstrap";
import { Url } from 'url';
import { getApiData, getBaseUrl } from '../../services/Api';
import Header from '../common/FrontHeader';
import '../../services/autocomplete/AutoComplete.css'
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import CryptoJS from "crypto-js";
import padZeroPadding from 'crypto-js/pad-zeropadding';

let key = encHex.parse("0123456789abcdef0123456789abcdef");
let iv =  encHex.parse("abcdef9876543210abcdef9876543210");  
class Agents extends React.Component {
    constructor(props) {
        super(props);     
     this.state={
        agentList: [],
       
        suggestions:[],
        agentValue:'',
        agentSearchList:[],

        //filter
        activeSuggestion: 0,
        // The suggestions that match the user's input
        filteredSuggestions: [],
        // Whether or not the suggestion list is shown
        showSuggestions: false,
        // What the user has entered
        userInput: ""
     }
     this.setWrapperRef1 = this.setWrapperRef1.bind(this);
     this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    setWrapperRef1(node) {
			this.wrapperRef1 = node;
      }
    handleClickOutside(event) {    
      if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
        this.setState({
      showSuggestions: false,
        })
      }
    }

      componentWillMount =()=>{
        this.getAgentList(); 
        this.getAgentListForSearch();
        document.addEventListener('mousedown', this.handleClickOutside);
      }
      componentDidMount() {
        window.scrollTo(0, 0);
       
      }
      // updateSuggestion=()=>{
      //     this.state.agentSearchList.map((item)=>{
      //       this.state.suggestions.push(item.name)
      //     })
      //   }

        getAgentListForSearch=()=> {
          let applicationList = [{
              url: "/front/agent/agentSearchList",
          }];
          
          getApiData(applicationList,false).then(res => {
              res.data.map((item)=>{
                return this.state.suggestions.push(item.name)
              })
          this.setState({'pageLoader':true},()=>{
                  this.setState({'pageLoader':false});
          })
          }).catch(error => {
          if(error.response){
              console.log("error :", error)
              this.setState({myerrormsg: error.response.data.error.message});
          }
      
          });
      }

      getAgentList=()=> {
        let applicationList = [{
            url: "/front/agent/agentList",
            where:{
              'agentValue':this.state.agentValue
            }
        }];
        
        getApiData(applicationList,false).then(res => {
        this.setState({'agentList': res.data, 'agentSearchList':res.data,'pageLoader':true},()=>{
            // this.updateSuggestion()
                this.setState({'pageLoader':false});
        })
        }).catch(error => {
        if(error.response){
            console.log("error :", error)
            this.setState({myerrormsg: error.response.data.error.message});
        }
    
        });
    }

    onChange = e => {
        const { suggestions } = this.state;
        const userInput = e.currentTarget.value;
    
        // Filter our suggestions that don't contain the user's input
        const filteredSuggestions = suggestions.filter(
          suggestion =>
            suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );
        
        // Update the user input and filtered suggestions, reset the active
        // suggestion and make sure the suggestions are shown
        this.setState({
          activeSuggestion: -1,
          filteredSuggestions,
          showSuggestions: true,
          userInput: e.currentTarget.value,
          agentValue: e.target.value
        },()=>{
          if(this.state.agentValue=='')
          {
            this.getAgentList();
           }
        });
        
      };
      
        // Event fired when the user clicks on a suggestion
  onClick = e => {
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
      agentValue : e.target.id
    },()=>{
      this.getAgentList();
    });
   
  };

  // Event fired when the user presses a key down
  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      if(filteredSuggestions[activeSuggestion]){
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: filteredSuggestions[activeSuggestion],
          agentValue: filteredSuggestions[activeSuggestion]
        },()=>{
          this.getAgentList();
        });
      }else{
        this.setState({
          showSuggestions: false,
          agentValue: this.state.agentValue
        },()=>{
          this.getAgentList();
        })
      }
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
              activeSuggestion,
              filteredSuggestions,
              showSuggestions,
              userInput,
              agentList
            }
          } = this;

          let suggestionsListComponent;
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  id={suggestion}
                  className={className}
                  key={suggestion}
                  onClick={onClick}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        );
      }
    }

        return (
   

            <div className="w-bg FrontEnd" style={{height:'100vh'}}> 
              <Header />           
                <div className="container workbody ourteam-section curtain" style={{maxWidth:'950px'}}>
                   <div className="search-section clearfix">
                         <h2 className="title pull-left">Meet Our Team</h2>
                         {/* <div className="search-block pull-right"><input type="text" onChange={this.handleChange} ></input>
                       
                         </div> */}
                           <div className="search-block pull-right" ref={this.setWrapperRef1}>
                                <input
                                class="input_suggestion"
                                type="text"
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                                value={userInput}
                                />
                                {suggestionsListComponent}
                            </div>
                    </div>
                   <div className="row clearfix">
                   {this.state.agentList.map((item,index)=>{
                        return <div className="col-lg-4 col-md-6 col-sm-12 col-xs-6" key={index}>
                            <Link to={'agentdetail/'+item.id}>
                                <div className="team-block">
                                  {/* {item.image=='member.png' || item.image=='' ?<div className="team-img"  id={item.id} style={{background:'url('+ getBaseUrl()+"/containers/agent_image/download/member.png"+') center', backgroundSize:'cover' }}  ></div>:<div className="team-img"  id={item.id} style={{background:'url('+item.image +') center', backgroundSize:'cover' }}  ></div>}      */}
                                  {item.image.startsWith("https://")?<div className="team-img"  id={item.id} style={{background:'url('+ item.image +') top', backgroundSize:'cover' }} ></div>:<div className="team-img"  id={item.id} style={{background:'url('+ getBaseUrl()+"/containers/agent_image/download/"+item.image+') top', backgroundSize:'cover'}}></div>}        
                                    
                                    
                                    <div className="team-info">
                                        <p className="name">{item.name}</p>
                                        <p className="client-title">{item.title}</p>
                                        <p className="phno">{(item.mob_number!== 'NA' || item.mob_number!== '')  ?aes.decrypt(item.mob_number, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8): ''}</p>
                                        <p className="email">{(item.email!== 'NA' ||item.email!== '') ? aes.decrypt(item.email, key, {iv: iv, padding:padZeroPadding}).toString(CryptoJS.enc.Utf8) :''}</p>
                                    </div>
                                </div>
                            </Link> 
                        </div>
                          })}
                   </div>

                 
                        <p className="text-center"><small>Are you an agent? <Link to="/frontend/join">Join us!</Link></small></p>
                        <div>
                            <nav className="footer-nav">
                                <ul className="nav">
                                    {/* <li className="nav-item"><Link to='/client/login' className="nav-link">LOG IN</Link></li> */}
                                    <li className="nav-item "><a href ="/frontend/landing#experience" className="nav-link">THE EXPERIENCE</a></li>
                                    <li className="nav-item active"><Link to ="/frontend/agents" className="nav-link">AGENTS</Link></li>
                                </ul>
                            </nav>

                        </div>

                    
                </div>
                <div className="Newskyward-design">
                <footer className>
                   <div className="container">
                   <ul className="navbar-nav">
                     
                     <li className="nav-item"><a href="/frontend/landing#experience" > The Experience</a></li>
                     <li className="nav-item"><Link to="/frontend/agents" > Agents</Link></li>
                     <li className="nav-item"><Link to="/frontend/join"> Join Us</Link></li>
                     {/* <li className="nav-item"><Link to="/client/listing/browselist" > Browse Listings</Link></li> */}
                     <li className="nav-item"><a href="https://blog.moveskyward.com/" target="_blank" > Blog </a></li>  
                     <li className="nav-item"><Link to="/frontend/privacy-policy"> Privacy Policy</Link></li>
                     <li className="nav-item"><Link to="/frontend/terms-and-condition"> Terms</Link></li>
                     
                  
                    
                    </ul>   
                    <div className="clearfix new_logo">
                       <ul className="left-nav"  style={{display:'none'}}>
                           <li><Link to="/frontend/career">CAREERS</Link></li>
                           <li><img className='logo_1st' src="/home-icon.png"/></li>
                       </ul>
                       {/* <img src="/NYCFC_AP3_Web.jpg"/> */}
                       <div className="logo" style={{marginTop:-36}}></div>
                       <ul className="right-nav" style={{display:'none'}}>
                           <li><a href="https://www.facebook.com/moveskyward/" target="_blank"><i className="fa fa-facebook-square"></i></a></li>
                           <li><a href="https://twitter.com/moveskyward/" target="_blank"><i className="fa fa-twitter"></i></a></li>
                           <li><a href="https://www.instagram.com/moveskyward/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                       </ul>
                   </div>
                   <p className='text_1' style={{marginTop:48}}>*Skyward Steel, LLC DBA Skyward and Skyward Real Estate is committed to adhering to the guidelines of The New York State Fair Housing Regulations.
<br></br>
*Standardized Operating Procedure for Purchasers of Real Estate Pursuant to Real Property Law 442-H. <a href="/Legal_SOP_NYSAR_032122.pdf" style={{textDecoration:'underline'}} target="_blank">To View Please Click Here.</a>


</p>      </div>
               </footer> 

                </div>
                


                
            </div>

                );
    }
}
export default Agents;
