import React from 'react';
import {Link} from 'react-router-dom';
import { getApiData, getToken, getUserId,getOnBoardingDone } from '../../services/Api';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.nav_bar_col = React.createRef();
        
        this.state = {
            showSign: true,
            user_id: localStorage.getItem('user_id'),
            user_name: ""

          }; 
          this.onLoad = this.onLoad.bind(this);
          this.setWrapperRef1 = this.setWrapperRef1.bind(this);
          this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        this.onLoad();
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    setWrapperRef1(node) {
        this.wrapperRef1 = node;
        }
        handleClickOutside(event) {
         // alert(event.target.id)              
        if (this.wrapperRef1 && !this.wrapperRef1.contains(event.target)) {
            document.getElementById('collapsibleNavbar').classList.remove("show");
          } 
       }  

    async onLoad(){
        if(getToken()){
            this.setState({'user_name':localStorage.getItem("user_name"),'showSign':false}); 
        }
    }

    render() {
        var imgUrl=localStorage.getItem('imageUrl');
        var flag=getOnBoardingDone();
        if(imgUrl=="" || imgUrl==null){
                imgUrl="/member.png";
        }
        return (
               <header className="FrontHeader">             
                <nav className="navbar navbar-expand-lg bg-light navbar-dark justify-content-center">
                <Link className="navbar-brand logo" to="/frontend/landing"><img src="/logo.png" width="100%" /></Link>
                <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <img src="/menu-icon.png"/>
                </button>
                
             
                       
                     { this.state.showSign?(
                         <div className="collapse navbar-collapse " id="collapsibleNavbar" ref={this.setWrapperRef1}>
                       
                     <ul className="navbar-nav mr-auto">
                     <li className="nav-item"><a href="/frontend/landing#experience" className="nav-link"> THE EXPERIENCE</a></li>
                     <li className="nav-item"><Link to="/frontend/agents" className="nav-link"> AGENTS</Link></li>
                     <li className="nav-item"><Link to="/frontend/join" target="_blank" className="nav-link"> JOIN US</Link></li>
                        {/* <li className="nav-item"><Link to="/client/listing/browselist" className="nav-link"> BROWSE</Link></li>  */}
                      
                        {/* <li className="nav-item"><Link to="/client/login" className="nav-link"> LOG IN</Link></li>
                     
                        <li className="nav-item"><Link to="/client/signup" className="nav-link btn btn-default" > GET STARTED</Link></li> */}
                     </ul>   </div>
                         ):(
                            <div className="collapse navbar-collapse " id="collapsibleNavbar">
                    <ul className="navbar-nav mr-auto">
                        {(flag== 1) ? <li className="visible-xs-block"><Link to="/client/profile" lass="nav-link">{imgUrl?(<img alt="Profile" src={imgUrl} width="40" className="user rounded-circle"/>):(null)}</Link></li>:(null)} 
                        <li className="nav-item"><Link to="/client/dashboard" className="nav-link"> HOME</Link></li> 
                        <li className="nav-item"><Link to="/frontend/landing" className="nav-link"> THE EXPERIENCE</Link></li>
                        <li className="nav-item"><Link to="/frontend/agents" className="nav-link"> AGENTS</Link></li>
                   
                        <li className="nav-item"><Link to="/client/listing/browselist" className="nav-link"> BROWSE</Link></li> 
                        <li className="nav-item"><Link to="/client/listing/savelisting" className="nav-link"> SAVED LISTINGS</Link></li>
                        <li className="nav-item"><Link to="/client/application" className="nav-link"> APPLY</Link></li>  

                        {/* {this.state.account_verify == 1  ? (flag== 1) ?<li className="nav-item"><Link to="/client/application" lass="nav-link">Apply</Link></li> :('')  :(null)} */}
 
  {(flag== 1 && this.state.account_verify==1 && this.state.account_verify!="") ?<li className="nav-item"><Link to="/client/application" lass="nav-link">Apply</Link></li> :('')}
                         <li className="nav-item"><Link to="/client/logout" className="nav-link"> LOGOUT</Link></li>
                        {(flag== 1) ? <li className="hidden-xs-block"><Link to="/client/profile" className="nav-link">{imgUrl?(<img alt="Profile" src={imgUrl} width="40" height="40" className="user rounded-circle"/>):(null)}</Link></li>:(null)}    
                    </ul></div>  
                       )
                    } 
                 
                
                </nav>
                </header>
                );
    }
}
export default Header;